<template>
  <div class="foot-bottom">
    <p>© 2009-{{ year }} 北京淘知在线教育科技有限公司 版权所有</p>
    <p>
      <span>京公网安备11010802035029号</span>
      <span><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备20022134号-2</a></span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'CLayoutCopyrightNotice',
  computed: {
    year() { return new Date().getFullYear(); },
  },
};
</script>

<style scoped lang="sass">
.foot-bottom
  height: 71px
  width: 100%
  background-color: #0C9CEF
  padding-top: 15.5px
  box-sizing: border-box

  p
    width: 100%
    text-align: center
    font-size: 12px
    color: #ffffff
    line-height: 20px

    span
      padding: 0 20px
</style>
